import React from "react"
import CTALink from "@components/elements/CTALink"
import "./IconCardsModule.scss"
import { Container } from "react-bootstrap"
import { parseHTML } from "@lib/utils"
import Slider from "@components/elements/Slider/Slider"
import clsx from "clsx"
import ArrowRight from "@components/icons/ArrowRight"

const IconCardsModule = ({ module }) => {
  if (!module) return null

  const { cards, title, description, slider, size, cta, bg_color } = module

  const Comp = slider ? Slider : "div"
  const CTA = getCTA(cta, { bg_color })
  let sliderSize = "lg"

  switch (size) {
    case "three":
      sliderSize = "sm"
      break
    case "four":
      sliderSize = "lg"
      break
    default:
      sliderSize = "lg"
  }

  return (
    <div className="icon-cards-wrap section-m">
      <Container className="icon-cards-container">
        <h2 className="title">{title}</h2>
        <div className="description">
          {parseHTML(description?.data.description)}
        </div>
        <Comp
          size={sliderSize}
          className={clsx(
            "icon-cards",
            { "icon-cards--grid": !slider },
            size && `icon-cards--grid-${size}`
          )}
        >
          {cards.map((card) => (
            <div className="icon-card" key={card.id}>
              <div className="icon-card-icon">
                <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={card.icon.url} alt="card icon" />
              </div>
              <div className="content-section">
                <p className="icon-card-heading">{card.heading}</p>
                <p className="icon-card-title">{card.title}</p>
                <p className="icon-card-description">{card.description}</p>
              </div>
            </div>
          ))}
        </Comp>
        <div className="bt-top">
        {CTA}
        </div>
      </Container>
    </div>
  )
}
export const getCTA = (cta, { bg_color } = {}) => {
  let CTA = null

  const isBlueBg = bg_color === "blue"

  if (cta) {
    if (cta?.custom_link?.startsWith("#")) {
      CTA = (
        <button
          className={clsx(
            "button ",
            isBlueBg ? "button-white-outline" : "button-orange"
          )}
          onClick={() => {
            scrollToID(cta.custom_link.slice(1))
          }}
        >
          <span>{cta.cta_label}</span>
          <ArrowRight color="white" />
        </button>
      )
    } else {
      CTA = (
        <CTALink
          cta={cta}
          className={clsx(
            "button ",
            isBlueBg ? "button-white-outline" : "button-orange"
          )}
        >
          <span>{cta.cta_label}</span>
          <ArrowRight color="white" />
        </CTALink>
      )
    }
  }

  return CTA
}
export default IconCardsModule
